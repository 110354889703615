import React from "react";
import SEO from "src/components/SEO";
import About from "src/sections/About";
import Footer from "src/sections/Footer";
import GenericPromo from "src/sections/GenericPromo";

// import { getUrlParam } from "src/helpers/gatsby";
import { NAME, BASE_URL } from "src/helpers/config";

const ThanksCustomer = () => {
  // const product = getUrlParam("product");

  // /obrigado-aluno/?product=1018329

  const promoProps = {
    title: "Muito Obrigado!",
    paragraphs: [
      "Muito obrigado por se tornar uma aluno(a)",
      "Verifique seu email, dentro de alguns minutos você irá receber os dado de acesso.",
      "Caso tenha qualquer dúvida, entre em contato no email alunos@emersonbroga.com.",
      "Muito obrigado mais uma vez e sucesso na sua carreira!",
    ],
  };

  const seoProps = {
    title: `Muito Obrigado - ${NAME}`,
    description: "Muito obrigado por se tornar uma aluno(a)",
    canonical: `${BASE_URL}/obrigado-aluno/`,
    image: `${BASE_URL}/meta/og-image-default.jpg`,
  };

  return (
    <>
      <SEO {...seoProps} />
      <GenericPromo {...promoProps} />
      <About />
      <Footer />
    </>
  );
};

export default ThanksCustomer;
