import React from "react";
import "./GenericPromo.scss";
const GenericPromo = ({ title, paragraphs }) => {
  return (
    <div className="generic-promo">
      <h1>{title}</h1>
      {paragraphs.map(text => (
        <p key="text">{text}</p>
      ))}

      <img
        loading="lazy"
        className="illustration"
        src="/static/illustrations/feeling_proud_qne1.svg"
        alt="Cadastro Confirmado"
      />
    </div>
  );
};
export default GenericPromo;
